<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="490">
            <v-card>
                <v-card-title class="text-h5">
                    <span class="lfont">{{ $t("adjustment.modal_edit.title") }}</span>
                </v-card-title>
                <v-card-text>

                    <label for="" class="lfont">{{ $t("adjustment.modal_edit.date") }}</label>
                    <DatePicker class="date-time" style="width: 100%;" type="date" valueType="format" slot="activator"
                        :append-to-body="true" v-model="editData.effective_date"></DatePicker><br><br>

                    <label for="" class="lfont">{{ $t("adjustment.modal_edit.salary") }}</label>
                    <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber"
                        v-model="editData.base_salary" persistent-hint type="number" required />

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text class="lfont" @click="updateBaseSalary">
                        {{ $t("adjustment.modal_edit.save") }}
                    </v-btn>
                    <v-btn color="green darken-1" text class="lfont" @click="$emit('close', false)">
                        {{ $t("adjustment.modal_edit.cancel") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    props: {
        dialog: "",
        editData: {}
    },
    data() {
        return {
        };
    },
    methods: {
        updateBaseSalary() {
            this.$emit("close", false);
            this.$emit("start");
            this.$axios
                .put(`company/adjustment/salary/update/${this.editData.id}`, this.editData)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.$emit("success");
                        setTimeout(() => {
                            this.$notification.OpenNotification_DeleteItem_OnSuccess(
                                "top-right",
                                "danger",
                                3000
                            );
                        }, 300);
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$emit("close");
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        }
    },
    created() {
        //console.log(this.editData)
    },
}
</script>

<style lang="scss" scoped></style>