<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="600px">
      <v-card>
        <v-card-title>
          <span class="lfont ml-2">{{ $t("adjustment.form.title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="label-input">{{
                  $t("adjustment.form.file")
                }}</label>
                <v-col class="mb-6 d-flex flex-column" cols="12">
                  <div class="choose-image">
                    <div class="show-image">
                      <i class="fas fa-cloud-upload-alt"></i>
                      <input @change="PreviewPDF" type="file" class="input-file-image" />
                      <!-- <p>File(PDF)</p> -->
                      <embed v-if="file_type_check == 'application/pdf'" class="embed-pdf" :src="showPDF"
                        type="application/pdf" accept="application/pdf" />
                      <img v-if="file_type_check == 'image/jpeg' ||
                        file_type_check == 'image/jpg' ||
                        file_type_check == 'image/png'
                        " :src="showPDF" alt="" />
                    </div>
                  </div>
                </v-col>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label class="label-input">{{
                  $t("adjustment.form.date")
                }}</label>
                <date-picker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                  :append-to-body="true" name="founding_date" v-model="effective_date"></date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mt-5">
                <label class="label-input">{{
                  $t("adjustment.form.from")
                }}</label>
                <v-text-field outlined disabled dense v-model="current_salary"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label class="label-input">{{
                  $t("adjustment.form.to")
                }}</label>
                <!-- <v-text-field
                  outlined
                  dense
                  :options="$store.state.formatSalaryNumber"
                  v-model="new_salary"
                ></v-text-field> -->
                <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" v-model="new_salary"
                  :hint="`${server_errors.new_salary}`" persistent-hint type="number" required />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveChange" class="lfont">
            {{ $t("adjustment.form.save") }}
          </v-btn>
          <v-btn color="dark" text @click="closeModal" class="lfont">
            {{ $t("adjustment.form.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { emit } from "process";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  props: ["dialog", "employee_id", "current_salary"],

  data() {
    return {
      file_type_check: "",
      showImage: "",
      showPDF: "",
      imagePDF: "",
      file_name: "",
      effective_date: "",
      new_salary: "",
      server_errors: {
        effective_date: "",
        new_salary: "",
      },
    };
  },
  methods: {
    PreviewPDF: function (event) {
      this.isLoading = true;

      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      this.file_type_check = file.type;
      const validImageTypes = [
        "application/pdf",
        "application/msword",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];

      if (!validImageTypes.includes(fileType)) {
        alert(fileType);
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be PDF"
        );
        this.isLoading = false;
      } else if (input.files && input.files[0]) {
        this.imagePDF = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showPDF = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadPDF(this.imagePDF);
      }
    },

    UploadPDF(imagePDF) {
      let formData = new FormData();
      formData.append("imageFile", imagePDF);
      this.$axios
        .post(`upload-file`, formData)
        .then((res) => {
          if (res.status === 200) {
            this.file_name = res.data.filename;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    saveChange() {
      const items = {
        file: this.file_name,
        employee_id: this.employee_id,
        effective_date: this.effective_date,
        new_salary: this.new_salary,
      };
      this.$axios
        .post(`company/adjustment/salary/add`, items)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.loading = false;
            this.$router
              .push({
                name: "adjustment-item.index",
              })
              .catch(() => { });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },

    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    showModal: function () {
      return this.dialog;
    },
  },
  created() { },
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}

.btn {
  padding: 30px !important;
}

.btn-save {
  color: white !important;
  margin-right: 30px;
}

.user {
  color: blue;
}
</style>
