<template>
  <div class="form-layout">
    <div class="block-content">
      <table class="table table-view">
        <thead>
          <tr>
            <th class="text-left">{{ $t("adjustment.modal_history.date") }}</th>
            <th class="text-left">{{ $t("adjustment.modal_history.salary") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in history.base_salary.history" :key="idx">
            <td>
              {{ item.effective_date }}
              <v-btn class="ml-2" icon x-small color="warning"
                @click="editSalaryAdjustment(item.id, item.effective_date, item.base_salary)">
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </td>
            <td>
              {{ $helpers.numberFormatter(item.base_salary) }}
              <v-btn class="ml-2" icon x-small color="warning"
                @click="editSalaryAdjustment(item.id, item.effective_date, item.base_salary,)">
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </td>
            <td width="20">
              <v-btn class="ml-2" icon x-small color="error" @click="deleteSalaryAdjuctment(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    history: {},
  },
  data() {
    return {};
  },
  methods: {
    deleteSalaryAdjuctment(id) {
      this.$emit('delete', id);
      this.$emit('close');
    },
    editSalaryAdjustment(id, effective_date, base_salary) {
      this.$emit('edit', { id: id, effective_date: effective_date, base_salary: base_salary })
      this.$emit('close')
    }
  },
};
</script>

<style scoped lang="scss">
.form-layout {
  padding: 10px;
  padding-bottom: 50px;
}

.table-detail {
  margin-top: 20px;
}

.table-detail tr td,
.table-detail tr th {
  border: 1px solid rgb(183, 183, 183) !important;
  font-family: $font-family;
}

.table-detail tr td {
  font-weight: 700;
  font-size: 16px;
}

.table-view td {
  border-right: 1px solid rgb(172, 172, 172);
}
</style>
